<template>
  <v-row class="support">
    <v-col cols="12" class="pa-0">
      <div class="title justify-space-between">
        <div class="white--text text-uppercase text-sm-h5 text-h6 font-weight-bold">support</div>
        <div class="d-flex title-input">
          <v-text-field
            v-model="search"
            placeholder="Search by number or title"
            prepend-inner-icon="mdi-magnify"
            :append-icon="search ? 'mdi-close' : ''"
            @click:append="clearSearch"
            outlined
            dense
            required
            color="input"
            @change="(page = 1), getData()"
          >
          </v-text-field>
          <v-btn @click="newItem = !newItem" class="white--text ml-5" width="184" height="48" color="secondary">Create new</v-btn>
        </div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-sm-15">
        <v-card flat height="70vh" min-height="260" v-if="nothing" class="pa-3 d-flex align-center justify-center flex-column">
          <v-img max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
          <div class="text-center text-h5 font-weight-bold mt-8">Sorry, but nothing found</div>
        </v-card>
        <v-card
          flat
          height="70vh"
          min-height="260"
          v-else-if="block || !data.result.length"
          class="pa-3 d-flex align-center justify-center flex-column"
        >
          <v-img max-height="124" contain src="@/assets/img/notTicket.svg"></v-img>
          <div class="text-center text-h5 font-weight-bold mt-8">You can submit the ticket to the support here</div>
          <div class="text-center mt-5">
            <v-btn color="primary" height="48" width="184" elevation="0" @click="newItem = !newItem">Create new</v-btn>
          </div>
        </v-card>
        <div v-else>
          <v-data-table
            hide-default-footer
            fixed-header
            :headers="headers"
            :items="data.result"
            item-key="name"
            :items-per-page="Number(itemsPerPage)"
            :height="`calc(100vh - 230px)`"
            class="d-sm-block d-none p-table"
          >
            <template v-slot:header.status>
              Status
              <v-menu bottom :close-on-content-click="true" transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :class="!selectedItem ? 'opasity--text white' : 'white--text input'"
                    class="pa-0"
                    height="24"
                    min-width="24"
                    x-small
                    text
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-filter mdi-18px</v-icon>
                  </v-btn>
                </template>
                <v-list-item-group v-model="selectedItem" color="primary">
                  <v-list>
                    <v-list-item v-for="(item, i) in statusList" :key="item.name" @click="valueSelect(item.name, i)">
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-list-item-group>
              </v-menu>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id" height="52" class="link" @click="openItem(item.id)">
                  <td>{{ item.number }}</td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.topic.title }}</td>
                  <td>
                    <div class="ml-auto status" :class="getColorClass(item.status.name)">{{ item.status.title }}</div>
                  </td>
                </tr>
              </tbody>
            </template>
            <template v-slot:footer>
              <v-divider></v-divider>
              <v-row class="mt-2 pb-2 px-10" align="center" justify="end">
                <span class="grey--text">Rows per page:</span>
                <div class="perPageSelect">
                  <v-select
                    v-model="itemsPerPage"
                    :items="itemsPerPageArray"
                    menu-props="auto"
                    hide-details
                    single-line
                    dense
                    @change="getData"
                  ></v-select>
                </div>
                <v-btn icon color="secondary" class="mr-1" @click="formerPage">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span class="mx-4 grey--text">{{ page }} of {{ data.pages }}</span>
                <v-btn icon color="secondary" class="ml-1" @click="nextPage">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
          <div class="d-block d-sm-none">
            <div v-for="item in data.result" :key="item.id" class="px-4 pt-2">
              <div v-for="head in headers" :key="head.value" class="d-flex my-1 link" @click="openItem(item.id)">
                <div class="opasity--text f18 font-weight-medium label-table">{{ head.text }}</div>
                <div class="font-weight-medium f18">{{ item[head.value].title || item[head.value] }}</div>
              </div>
              <v-divider></v-divider>
            </div>
            <v-row class="my-2 pb-2" align="center" justify="center">
              <span class="grey--text">Rows per page:</span>
              <div class="perPageSelect">
                <v-select
                  v-model="itemsPerPage"
                  :items="itemsPerPageArray"
                  menu-props="auto"
                  hide-details
                  single-line
                  dense
                  @change="getData"
                ></v-select>
              </div>
              <div>
                <v-btn icon color="secondary" class="mr-1" @click="formerPage">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span class="mx-3 mt-1 grey--text">{{ page }} of {{ data.pages }}</span>
                <v-btn icon color="secondary" class="ml-1" @click="nextPage">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </v-row>
          </div>
        </div>
      </v-card>
      <v-dialog v-model="newItem" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4">
          <div class="text-h5 font-weight-bold mb-6">Add ticket</div>
          <v-select
            :items="selectItems"
            v-model="general.topic"
            :error-messages="topicErrors"
            label="Topic"
            item-text="title"
            item-value="name"
            filled
            outlined
            dense
            required
            color="input"
          >
          </v-select>
          <v-text-field
            v-model="general.title"
            :error-messages="titleErrors"
            label="Title"
            filled
            outlined
            dense
            required
            color="input"
          ></v-text-field>
          <v-textarea
            v-model="general.description"
            label="Details"
            placeholder="Describe the details of your problem"
            filled
            outlined
            dense
            color="input"
            rows="5"
            auto-grow
          ></v-textarea>
          <div class="mt-7 d-flex flex-column align-center">
            <v-btn elevation="0" width="184" height="48" color="primary" @click="createItem">Submit</v-btn>
            <v-btn width="184" height="48" text color="secondary" class="mt-3" @click="newItem = !newItem">Close</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  validations: {
    general: {
      topic: { required },
      title: { required },
    },
  },
  data() {
    return {
      general: {},
      search: this.$route.query.src || '',
      itemsPerPage: Number(this.$route.query.page_size) || 10,
      page: Number(this.$route.query.page) || 1,
      status: this.$route.query.status || 'all',
      selectedItem: 0,
      load: true,
      block: true,
      nothing: false,
      newItem: false,
      headers: [
        { text: 'Number', value: 'number', sortable: false },
        { text: 'Title', value: 'title', sortable: false },
        { text: 'Topic', value: 'topic', sortable: false },
        { text: 'Status', align: 'end', value: 'status', sortable: false },
      ],
      statusError: null,
      error: null,
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch('getPagination');
    this.$store.dispatch('getTicketStatus');
    this.$store.dispatch('getTicketTopic');
  },
  methods: {
    notifi(btn) {
      if (btn == 'create') {
        this.$notify({ message: `Ticket ${this.general.title} create`, horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'search400') {
        this.$notify({
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    getColorClass(block) {
      if (block == 'submitted') {
        return 'cyan';
      } else if (block == 'processing') {
        return 'success';
      } else if (block == 'reopened') {
        return 'input';
      } else if (block == 'closed') {
        return 'error';
      }
    },
    valueSelect(status, i) {
      this.selectedItem = i;
      this.status = status;
      this.getData();
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getData();
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async createItem() {
      this.error = null;
      this.$v.general.$touch();
      if (!this.$v.general.$invalid) {
        const data = new Object();
        data.topic = this.general.topic;
        data.title = this.general.title;
        data.details = this.general.description;
        await this.$store
          .dispatch('createTicket', data)
          .then((res) => {
            this.notifi('create');
            this.openItem(res.data.id);
          })
          .catch((e) => {
            this.error = e.response.data.error[0];
          });
      }
    },
    openItem(id) {
      this.$router.push({ name: 'Ticket', params: { supportId: id } });
    },
    async getData() {
      await this.$store
        .dispatch('getSupportList', {
          pageSize: this.itemsPerPage,
          search: this.search,
          page: this.page,
          status: this.status,
        })
        .then(() => {
          this.nothing = false;
          this.block = false;
        })
        .catch((e) => {
          this.getDataError(e);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getDataError(e) {
      console.log(e);
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (e.response.data.error[0] === 'account__not_found') {
        this.$store.dispatch('setSupportList', {});
        this.block = true;
      } else if (e.response.data.error[0] === 'result__not_found') {
        this.$store.dispatch('setSupportList', {});
        this.nothing = true;
      } else if (e.response.data.error[0] === 'src__invalid') {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  watch: {
    newItem() {
      this.general = {};
      this.$v.general.$reset();
    },
    statusList() {
      this.statusList.find((item, i) => {
        item.name == this.$route.query.status ? (this.selectedItem = i) : 0;
      });
    },
  },
  computed: {
    data() {
      return this.$store.getters.supportList;
    },
    itemsPerPageArray() {
      return this.$store.getters.pagination;
    },
    selectItems() {
      return this.$store.getters.ticketTopic;
    },
    statusList() {
      return this.$store.getters.ticketStatus;
    },
    topicErrors() {
      const errors = [];
      if (!this.$v.general.topic.$dirty) {
        return errors;
      }
      !this.$v.general.topic.required && errors.push('Please enter ticket topic');
      this.error == 'topic__invalid' && errors.push('Provided ticket topic is not valid');
      return errors;
    },
    titleErrors() {
      const errors = [];
      if (!this.$v.general.title.$dirty) {
        return errors;
      }
      !this.$v.general.title.required && errors.push('Please enter ticket title');
      this.error == 'title__invalid' && errors.push('Provided ticket title is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setSupportList', {});
  },
};
</script>

<style lang="scss">
.support {
  .status {
    color: white;
    line-height: 24px;
    padding: 0 16px;
    font-size: 14px !important;
    width: max-content;
    border-radius: 24px;
  }
}
</style>
